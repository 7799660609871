// any CSS you import will output into a single css file (app.css in this case)
// noinspection JSUnusedLocalSymbols

import './styles/app.css';

// start the Stimulus application
import './bootstrap';

// Play sounds on menu hover
const menuHits = document.getElementsByClassName('menu-hit');
const menuHitAudio = document.getElementById('menu-hit-0');
const menuConfirmAudio = document.getElementById('confirm-sound-0');
const itemSoundAudio = document.getElementById('item-sound-0');
const menuHitPlayers = document.getElementById('audio-players');
const mainMenuToggle = document.getElementById('main-menu-toggle');
const mainMenuContainer = document.getElementById('main-menu-container');

function playMenuSoundEvent(soundMenuId) {
    if (typeof soundMenuId === 'number') {
        return function () {
            const matchingAudio = document.getElementById('menu-hit-' + soundMenuId);

            if (typeof matchingAudio !== 'undefined' && matchingAudio !== null) {
                matchingAudio.play();
            }
        };
    }

    return function () {
    };
}

if (typeof menuHits !== 'undefined' && typeof menuHitAudio !== 'undefined') {
    let menuId = 0;
    Array.prototype.forEach.call(menuHits, function (menuhit) {
        menuHitAudio.volume = 0.02;

        if (menuId !== 0) { // only clone if more than one needed
            let clonedMenu = menuHitAudio.cloneNode(true);
            clonedMenu.setAttribute('id', 'menu-hit-' + menuId);
            clonedMenu.volume = 0.02;
            menuHitPlayers.appendChild(clonedMenu);
        }

        menuhit.addEventListener('mouseenter', playMenuSoundEvent(menuId));

        menuId++;
    });
}

// Play sounds on links, inputs, select click
let allInputs = document.getElementsByTagName('input');
let allSelects = document.getElementsByTagName('select');
let allButtons = document.getElementsByTagName('button');
let allLinks = document.getElementsByTagName('a');
menuConfirmAudio.volume = 0.02;
itemSoundAudio.volume = 0.02;

for (let id = 0, len = allInputs.length; id < len; id++) {
    allInputs[id].onfocus = function (e) {
        itemSoundAudio.play();
    }
}

for (let id = 0, len = allSelects.length; id < len; id++) {
    allSelects[id].onfocus = function (e) {
        menuConfirmAudio.play();
    }
}

for (let id = 0, len = allButtons.length; id < len; id++) {
    allButtons[id].onfocus = function (e) {
        menuConfirmAudio.play();
    }
}

for (let id = 0, len = allLinks.length; id < len; id++) {
    allLinks[id].onclick = function (e) {
        menuConfirmAudio.play();
    }
}


// Toggled elements on search forms
const searchFormToggleLink = document.getElementById('tournament-search-form-toggle');
const searchForm = document.getElementById('tournament-search-form');

function toggleSearchForm() {
    if (typeof searchForm !== 'undefined' && searchForm !== null && typeof searchFormToggleLink !== 'undefined' && searchFormToggleLink !== null) {
        searchForm.classList.toggle('form-collapsed');
        searchFormToggleLink.classList.toggle('toggle-collapsed');
    }
}

if (typeof searchFormToggleLink !== 'undefined' && searchFormToggleLink !== null) {
    searchFormToggleLink.addEventListener('click', function () {
        toggleSearchForm()
    });
}

// Toggle main menu on click/tap
if (typeof mainMenuToggle !== 'undefined' && typeof mainMenuContainer !== 'undefined' && mainMenuContainer !== null && mainMenuToggle !== null) {
    mainMenuToggle.addEventListener('click', function () {
        mainMenuContainer.classList.toggle('menu-open');
    });
}
